<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <!--  -->
    <div class="NewsDetails">
      <div class="NewsDetails_cont">
        <div class="NewsDetails_cont_title">
          <h1>{{data.title}}</h1>
        </div>
        <div class="NewsDetails_cont_text" v-html="data.htmlText">
        </div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import { serversDetail } from '../api/index'
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data () {
    return {
      h1: "服务案例",
      Img1: require("../assets/img/banner2.png"),
      p1: "",
      id: this.$route.params.id,
      data: {}
    };
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      serversDetail(this.id).then((res) => {
        this.data = res.data
      })
    }
  },
};
</script>
<style scoped>
.NewsDetails {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont {
  width: 1200px;
  margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_text {
  margin: 40px 0;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
</style>


